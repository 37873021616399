import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';
// create a new axios instance
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    
})

instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
    // NProgress.configure({ easing: 'ease', speed: 500 });
    NProgress.start();
    return config
})

// before a response is returned stop nprogress
// instance.interceptors.response.use(response => {
//     NProgress.done()
//     return response
// })

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    NProgress.done()
    return response
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    NProgress.done()
    return Promise.reject(error);
  });

export default instance