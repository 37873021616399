import http from '../../http';

export const state = {
    token: null,
    user: null, 
    permissions: null,
};

export const getters = {
    authenticated(){
        return !!localStorage.getItem('currentUser');
        // return state.token != null || state.user != null || localStorage.getItem('token') != null;
    },

    user(){
        return JSON.parse(localStorage.getItem('currentUser'));
    },

    permisions(){
        return JSON.parse(localStorage.getItem('userPermissions'));
    },

    allowedCamps(){
        return JSON.parse(localStorage.getItem('userAllowedCamps'));
    }
};

export const mutations = {
    SET_TOKEN(state, token){
        state.token = token;
        saveState('userToken', token);

    },

    SET_USER(state, data){        
        state.user = data;
        saveState('currentUser', data);
    },

    SET_PERMISSIONS(state, data){
        state.permissions = data;
        saveState('userPermissions', data);
    },

    SET_ALLOWED_CAMPS(state, data){
        state.allowedCamps = data;
        saveState('userAllowedCamps', data);
    }

};

export const actions = {

    logout({ commit }, userAgent){
        return http.post("/auth/logout", { userAgent: userAgent }).then(() => {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
            commit('SET_PERMISSIONS', null);
            commit('SET_ALLOWED_CAMPS', null);
        });
    },
    async signIn({ dispatch }, credentials) {        
        let response = await http.post("/auth/login", credentials);
        if(response.data.status == 401){
            return {'status': 401, 'msg': response.data.status_msg};
        }else{
            dispatch('attempt', response.data.token);
        }
        
    },

    async attempt({commit, state}, token){
        if(token){
            commit('SET_TOKEN', token)
        }
        if(!state.token){
            return;
        }
        try {
            let response = await http.get("/auth/me");
            commit('SET_USER', response.data);
            commit('SET_PERMISSIONS', response.data.permissions);
            commit('SET_ALLOWED_CAMPS', response.data.allowedCamps);
            window.location.href = "/";
            
        } catch (error) {
            console.log(error);
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
        }
    },

    validate() {
        return localStorage.getItem('currentUser');
    },

};

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}