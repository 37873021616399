<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted() {

  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    }
  },
  methods: {
    clearNotification: notificationMethods.clear
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<style>

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/css/poppins/pxiEyp8kv8JHgFVrJJbecmNE.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/css/poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/css/poppins/pxiEyp8kv8JHgFVrJJfecg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/css/poppins/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}










::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


.badge {
  color:aliceblue;
}

.settingIcon {
  font-size: 60px;
}

.settingsPage .card {
margin-bottom: 0px !important;
}
.inlb {
  display: inline-block;
}

.quickAction-a {
  color: #3498db;
  text-decoration: underline !important;
  cursor: pointer;
  float: right
}

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed !important;
  background: #d6d9dc !important;
}
.disabledMS {
  cursor: not-allowed !important;
  background: #d6d9dc !important;
}

.disabledMS .multiselect__tags {
  cursor: not-allowed !important;
  background: #d6d9dc !important;
}

.disabledMS .multiselect__single {
  cursor: not-allowed !important;
  background: #d6d9dc !important;
}

.multiselect:disabled {
  cursor: not-allowed !important;
  background: #d6d9dc !important;
}

.quickAction-a:hover {
  color: #3498db;
  text-decoration: underline;
}

input:disabled {
  cursor: not-allowed;
  background: #d6d9dc !important;
}

a:disabled {
  cursor: not-allowed;
}

button:disabled {
  cursor: not-allowed !important;
}

button.disabled {
  cursor: not-allowed !important;
}

.isDisabled {
  color: currentColor !important;;
  cursor: not-allowed !important;;
  opacity: 0.5 !important;;
  text-decoration: none !important;;
}
.disabledBG{
    background-color: #D6D9DC !important;
    opacity: 1 !important;
  }

.isDisabledDropdown {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}
.multiselect__select {
  z-index: 9999 !important;
}
.multiselect.is-invalid {
  border-color: #f46a6a;
  border: 1px solid #f46a6a;
  border-radius: 5px;
  z-index: 9999;
  padding-right: calc(-1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.vm {
  max-width: 880px !important;
}

.star {
  color: red;
 }
 .star.active {
  color: red;
 }

.star-poor {
  background: red !important;
}

.azmt-service-card {
  border-radius: 10px !important;
}

.azmt-service-card h5 {
  color: #2a3042;
}
.azmt-service-card h4 {
  color: #2a3042;
  font-size: 22px !important;
}

.azmt-service-card img {
  width: 120px;
  margin-top: 10%;
  margin-bottom: 10%;
}

.disabledItem
{
  cursor: not-allowed;
  opacity: 0.5;
  background: #CCC;
}

.input-success {
  color: #2ecc71;
}

.input-success input{
  border: 1px solid #2ecc71;
}

.input-warning {
  color: #e67e22;
}

.input-warning input{
  border: 1px solid #f1c40f;
}

.input-danger {
  color: #e74c3c;
}

.input-danger input{
  border: 1px solid #e74c3c;
}

.ebsC {
  cursor: pointer;
  transition:.2s all;
}

table:hover .ebsC{
  filter:blur(3px);
  opacity:.5;
  transform: scale(.98);
  box-shadow:none;
}

table:hover .ebsC:hover{
  transform:scale(1);
  filter:blur(0px);
  opacity:1;
}

.fullWidthModal {
  max-width: 100% !important;
}

.white-loading-spinner .text-primary {
  color: #34495e;
}

.nav-link.disabled {
  cursor: not-allowed !important;
  color: #c8cbd9 !important;
}

.hosting-actions img{
  width: 25px;
}

/* .hosting-actions .btn {
  margin-bottom: 0px !important;
  margin-left: 10px !important;
} */

.hosting-actions .filtersBtn {
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.dd-actions {
  margin-right: 0px !important
}
.dd-actions .dropdown-toggle {
  padding-right: 0px !important;
}

.btn-check {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn.btn-outline.btn-outline-dashed.btn-outline-default.active, .btn.btn-outline.btn-outline-dashed.btn-outline-default.show, .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active), .show > .btn.btn-outline.btn-outline-dashed.btn-outline-default {
    color: #009ef7;
    border-color: #009ef7;
    background-color: #ecf8ff;
}

.btn.btn-outline-dashed {
    border: 2px solid #3498db;
    
}

.right-bottom-corner {
  position:fixed;
    right:10px;
    bottom: 20px 
}

.fs-18 {
  font-size: 18px;
}

.dd-actions .btn:first-child {
  margin-bottom: 0px !important;
}

.dd-left-actions .btn .btn-outline-primary {
  margin-bottom: 0px !important;
}

.dd-left-actions {
  margin-right: 0px !important
}
.dd-left-actions .dropdown-toggle {
  padding-right: 0px !important;
}

.searchBtn {
  margin-bottom: 0px !important;
}

.vld-overlay.is-full-page {
  z-index: 9999 !important;
}

.card {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.actionsBtns {
  float: right;
  font-size: 30px;
}
.actionsBtns a {
  margin-left: 10px;
}

/* BOOKING */

.room_booked {
  cursor: pointer;
  background: #34c38f;
  color: white;
  border: 0px !important;
  border-bottom: 1px solid white !important;
}

.room_assigned {
  cursor: pointer;
  background: #556ee6;
  color: white;
  border: 0px !important;
  border-bottom: 1px solid white !important;
}

.room_unavailable {
  cursor: pointer;
  background: #f46a6a;
  color: white;
  /* border: 0px !important; */
  border-bottom: 1px solid white !important;
}

.room_available {
  cursor: pointer;
  background: white;
  color: white;
  /* border: 0px !important; */
  border-bottom: 1px solid white !important;
}

.room_in_maintenance {
  cursor: pointer;
  background: #f1b44c;
  color: black;
  /* border: 0px !important; */
  border-bottom: 1px solid white !important;

}

.room_out_of_service {
  cursor: pointer;
  background: rgba(113, 117, 138, 0.25) !important;
  color: black;
  /* border: 0px !important; */
  border-bottom: 1px solid white !important;
}

.colorsHelp span {
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.room_status_table {
  text-align: center;
}

.full-width {
  width: 100% !important;
}

.splited-cell {
  display: grid;
  width: max-content;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  border: 1px solid #000;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='black' vector-effect='non-scaling-stroke'/></svg>");
  background-size: 100% 100%;
  margin-bottom: 1rem;
}

.cell--topRight {
  grid-column-start: 2;
  text-align: right;
}

.cell--bottomLeft {
  grid-column-start: 1;
}
.vm-wrapper {
z-index: 9999 !important;
}

.checkInModal .vm-titlebar {
  border-bottom: 0px !important;
}

.checkInModal {
  border-radius: 10px;
}

.checkInModal .bookingDataContainer {
  background-color: #ecf0f1;
  padding: 20px;
  /* margin-right: 20px;
  margin-left: 20px; */
  border-radius: 20px;
}

.checkInModal img {
  width: 50px;
}

.bookingDataContainer {
  font-size: 18px;
}

.bookingDataContainer label {
  color: #7f8c8d;
}

.singleBookingActions button {
  margin-left: 20px;
}

.ebs-upload .dz-message {
  margin: 0.5em !important
}

.popover {
  background: #2c3e50;
}

.popover .popover-body {
    color: white !important;

}
.settings .setting-item {
  text-align: center;
}

.settings .setting-item .card {
  /* height: 140px !important; */
  box-shadow: none !important;
  border: 2px solid #34495e;
  border-radius: 10px;
  width: 90%;
}

.settings .setting-item img {
  width: 80px;
  margin-bottom: 15px;
}

.activeTr {
  background: #556ee6;
}

.activeTr a {
  color: white
}

.contractDashboard .card {
  border-radius: 8px !important;
}

.contractDashboard .badge-soft-success {
  color: #34c38f !important
}

.contractDashboard .badge-soft-danger {
  color: #f46a6a !important
}

.auth-body-bg {
  overflow: hidden !important;
  width: auto !important;
}

.va-middle th,td{
  vertical-align: middle !important;
}

.swal2-container {
  z-index: 999999;
}

.activeActionLink {
  color: #2ecc71;
}

.disabled-link {
  cursor: not-allowed !important;
  color: #c8cbd9 !important;
}

.helpIcon {
  cursor: pointer;
  color: #50a5f1;
}

.f-h {
  height: 100% !important;
}

.br-solid {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.small-img {
  width: 120px;
}
</style>
